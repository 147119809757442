body {
  background-color: #efefef;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #e20000 !important;
}

a:active {
  color: #e20000 !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Algerian";
  src: local("Algerian"), url(./fonts/ALGER.ttf) format("truetype");
}

div.background {
  height: 100vh; /* Fallback */
  height: var(--app-height);
  background-size: cover;
}

.rasputin-title {
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 1);
  font-family: Algerian;
  font-size: 90px;
  line-height: 1;
  margin: 24px;
  user-select: none;
}

.rasputin-font {
  font-family: Algerian;
}

.container-wrapper {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  height: 100vh; /* Fallback */
  height: var(--app-height);
  text-align: center;
  overflow: hidden;
  align-items: center;
  font-size: calc(10px + 2vmin);
  margin-inline: 8px;
}

.navigation-bar {
  justify-content: center;
  margin-bottom: 10px;
}

.paper-wrapper {
  flex-grow: 1;
}

.paper {
  flex-grow: 1;
  padding: 24px;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.paper-content {
  margin: 1% 4%;
  text-align: left;
}

.icon {
  text-align: center;
  margin-top: 20%;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier();
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
}

.sub-text {
  font-size: 0.65rem;
  color: #646464;
}

.songlist-category {
  margin-bottom: 0px;
  user-select: none;
}

.songlist-row {
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
}

.song-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.song-category {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1%;
}

.song-title {
  font-size: 2rem;
  line-height: normal;
  margin-bottom: 1%;
  text-align: center;
}

.song-tune {
  font-size: 1.2rem;
  font-style: italic;
}

.song-lyrics {
  font-size: 1rem;
  margin-top: 3%;
  white-space: pre-wrap;
}

.bottom-carousel {
  width: 100vw;
  padding-top: 10px;
}

.bottom-carousel-content {
  height: 8vh;
  line-height: 8vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  max-width: 600px;
  user-select: none;
}

.search-container {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-container {
  display: flex;
}

.ant-input-affix-wrapper {
  border-radius: 24px !important;
  margin: 4px;
}

.ant-btn-icon-only.ant-btn-lg {
  border-radius: 50% !important;
  margin: 2px !important;
}

.ant-collapse-header {
  padding-inline: 0px !important;
  padding-block: 16px !important;
}
.ant-input-prefix {
  margin: 2px;
}

.anticon-search > svg {
  height: 20px;
  width: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.stripes {
  height: 120px;
  width: 120px;
  position: absolute;
  top: 0;
  right: 0;
}

.footer-container {
  background: #e20000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-logo {
  height: 48px;
  width: auto;
  margin-block: 8px;
  margin-inline: 12px;
}

.footer-text {
  color: white;
  margin-block: 4px;
}

.footer-ig {
  display: flex;
  color: white !important;
  margin: 8px;
  & > * > svg {
    height: 28px;
    width: 28px;
  }
}

@media (max-width: 768px) {
  .stripes {
    height: 20vmin;
    width: 20vmin;
  }
  .rasputin-title {
    font-size: 14vmin;
    margin: 16px;
  }
  .paper {
    padding: 4px;
  }
  .search-container {
    margin-block: 8px;
  }
}
